<template>
  <div class="wa__modeling__offer">
    <template>
      <v-container class="py-1 d-none d-md-block">
        <div>
          <SocialIcon />
        </div>
      </v-container>
      <Header />
      <main>
        <div class="social__divider__wrapper">
          <SocialDivider :title="$_t('title')" />
        </div>
        <v-container fluid>
          <list>
            <div class="offer__base wa__f__m__l">
              <div class="mt-4">
                <div class="d-flex justify-center mb-10">
                  <img :src="offerResponse.thumbnail" class="rounded" alt="" />
                  <!-- <v-img
                  :src="offerResponse.image"
                  class="rounded"
                  max-width="640"
                  max-height="360"
                  width="1280"
                  height="720"
                ></v-img> -->
                </div>
              </div>
              <h1 class="offer__title wa__f__m__l">
                {{ offerResponse.title }}
              </h1>
              <div class="mt-10">
                <p class="mb-0" v-html="offerResponse.description"></p>
              </div>
              <hr class="social__hr" />
              <v-form @submit.prevent="sendResponse" ref="modelingOfferForm">
                <div class="mt-16 pt-7">
                  <div class="registered__on text-uppercase mb-3">
                    {{ $_tc("emailText", 1) }}
                    <span> {{ $_tc("emailText", 2) }}</span>
                  </div>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        outlined
                        :label="$_t('email')"
                        class="show-error"
                        dense
                        color="SonicSilver"
                        :rules="requiredRules"
                        v-model="form.email"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <div class="mt-4">
                    <div class="text-uppercase font__18 shooting__date">
                      Shooting date / 撮影日
                    </div>
                    <div>
                      <v-row
                        class="mt-2"
                        v-for="(date, index) in offerResponse.dates"
                        :key="date.id"
                      >
                        <v-col cols="12" md="3"
                          ><span
                            class="shooting__date--date mr-7"
                            :class="
                              !form.shooting_dates[index].status && isSubmitOnce
                                ? 'text-danger'
                                : ''
                            "
                            >{{ date.date }} {{ date.begin_time }}:</span
                          ></v-col
                        >
                        <v-col cols="12" md="9"
                          ><v-item-group v-model="form.shooting_dates[index].status">
                            <v-item
                              value="1st_keep"
                              v-slot="{ active, toggle }"
                              class="mr-2"
                            >
                              <block-button
                                :style="
                                  active
                                    ? 'color: var(--color-black) !important;background-color: var(--color-light-gray) !important;'
                                    : ''
                                "
                                text="1st keep"
                                :class="[
                                  active ? 'active' : '',
                                  'btn-intended mb-2 pa-4',
                                ]"
                                @click.native="toggle"
                                height="27"
                                color="black"
                                bg-color="white"
                              ></block-button>
                            </v-item>
                            <v-item
                              value="2nd_keep"
                              v-slot="{ active, toggle }"
                              class="mr-2"
                            >
                              <block-button
                                :style="
                                  active
                                    ? 'color: var(--color-black) !important;background-color: var(--color-light-gray) !important;'
                                    : ''
                                "
                                text="2nd keep"
                                :class="[
                                  active ? 'active' : '',
                                  'btn-intended mb-2  pa-4',
                                ]"
                                @click.native="toggle"
                                height="27"
                                color="black"
                                bg-color="white"
                              ></block-button>
                            </v-item>
                            <v-item v-if="date.require" class="mr-2">
                              <v-chip
                                class="shooting__date__note mb-2 pa-4"
                                height="3"
                                color="grey lighten-3"
                                label
                              >
                                <span class="shooting__date__note--title"
                                  >Note!</span
                                >
                                &nbsp;Your presence is required on this day to
                                hire in this project
                              </v-chip>
                            </v-item>
                            <v-item
                              v-else
                              value="ng"
                              v-slot="{ active, toggle }"
                              class="mr-2"
                            >
                              <block-button
                                :style="
                                  active
                                    ? 'color: var(--color-black) !important;background-color: var(--color-light-gray) !important;'
                                    : ''
                                "
                                text="NG"
                                :class="[
                                  active ? 'active' : '',
                                  'btn-intended mb-2  pa-4',
                                ]"
                                @click.native="toggle"
                                height="27"
                                color="black"
                                bg-color="white"
                              ></block-button>
                            </v-item> </v-item-group
                        ></v-col>
                      </v-row>
                    </div>
                  </div>
                  <div class="mt-5">
                    <!-- <div class="text-uppercase nearest__station--label">
                      {{ $_t("nearestStation") }}
                    </div> -->
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          outlined
                          :label="$_t('nearestStation')"
                          class="show-error"
                          dense
                          color="SonicSilver"
                          :rules="requiredRules"
                          v-model="form.nearest_station"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="mt-9">
                    <div class="font__18 text-uppercase">
                      {{ $_t("upload") }}
                    </div>
                    <div class="d-flex mt-1">
                      <v-item-group v-model="form.is_change_image">
                        <v-item
                          :value="1"
                          v-slot="{ active, toggle }"
                          class="mr-2"
                        >
                          <block-button
                            :style="
                              active
                                ? 'color: var(--color-black) !important;background-color: var(--color-light-gray) !important;'
                                : ''
                            "
                            text="yes"
                            :class="[
                              active ? 'active' : '',
                              'btn-intended mb-2 pa-4',
                            ]"
                            @click.native="toggle"
                            height="27"
                            color="black"
                            bg-color="white"
                          ></block-button>
                        </v-item>
                        <v-item
                          :value="0"
                          v-slot="{ active, toggle }"
                          class="mr-2"
                        >
                          <block-button
                            :style="
                              active
                                ? 'color: var(--color-black) !important;background-color: var(--color-light-gray) !important;'
                                : ''
                            "
                            text="no"
                            :class="[
                              active ? 'active' : '',
                              'btn-intended mb-2  pa-4',
                            ]"
                            @click.native="toggle"
                            height="27"
                            color="black"
                            bg-color="white"
                          ></block-button>
                        </v-item>
                      </v-item-group>
                    </div>
                  </div>
                  <div class="mt-3" v-if="form.is_change_image">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <v-textarea
                          rows="2"
                          class="show-error"
                          :label="$_t('updateImage')"
                          dense
                          color="SonicSilver"
                          outlined
                          :rules="requiredRules"
                          v-model="form.change_image_comment"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="mt-8">
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          rows="5"
                          color="SonicSilver"
                          :label="$_t('comment')"
                          dense
                          outlined
                          v-model="form.comment"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row class="mt-6" v-if="offerResponse.has_required_files">
                    <v-col cols="12">
                      <div class="text-uppercase dropzone--label">
                        {{ offerResponse.required_files_label }}
                        {{
                          offerResponse.required_files_max_count
                            ? ` (up to ${offerResponse.required_files_max_count} file)`
                            : ""
                        }}
                      </div>
                      <div class="upload mt-2">
                        <div class="w-100 h-100">
                          <Dropzone
                            model-name="jobOfferResponse"
                            :batch-id="batch_id"
                            :max-files="offerResponse.required_files_max_count"
                            :defaultMessage="dropzoneText"
                          />
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6" v-if="offerResponse.questions">
                    <v-col
                      cols="12"
                      v-for="(question, index) in offerResponse.questions"
                      :key="index"
                    >
                      <div v-if="question.type === 'input'">
                        {{ question.name }}
                        <v-text-field
                          outlined
                          class="show-error"
                          dense
                          color="SonicSilver"
                          :rules="requiredRules"
                          v-model="form.question_values[index].value"
                        ></v-text-field>
                      </div>
                      <div v-else-if="question.type === 'select'">
                        <div class="mt-9">
                          <div class="font__18 text-uppercase" :class="
                              !form.question_values[index].value.length && isSubmitOnce
                                ? 'text-danger'
                                : ''
                            ">
                            {{ question.name }}
                          </div>
                          <div class="d-flex mt-1">
                            <v-item-group multiple v-model="form.question_values[index].value">
                              <v-item
                                v-for="(value,index) in question.values"
                                :key="index+1000"
                                :value="value"
                                v-slot="{ active, toggle }"
                                class="mr-2"
                              >
                                <block-button
                                  :style="
                                    active
                                      ? 'color: var(--color-black) !important;background-color: var(--color-light-gray) !important;'
                                      : ''
                                  "
                                  :text="value"
                                  :class="[
                                    active ? 'active' : '',
                                    'btn-intended mb-2 pa-4',
                                  ]"
                                  @click.native="toggle"
                                  height="27"
                                  color="black"
                                  bg-color="white"
                                ></block-button>
                              </v-item>
                            </v-item-group>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-15">
                    <v-col cols="12">
                      <v-card outlined height="300" class="overflow-auto px-15">
                        <div
                          class="
                            font-weight-bold
                            text-center
                            my-3
                            privacy__policy__title
                          "
                        >
                          個人情報保護方針
                        </div>
                        <div class="privacy__policy__text" v-html="privacyPolicy"></div>
                      </v-card>
                      <div>
                        <v-checkbox
                          color="black"
                          :rules="requiredRules"
                          large
                          class="checkbox--check"
                        >
                          <template v-slot:label>
                            <div class="checkbox-label">
                              By checking this box, you are agreeing to our
                              privacy policy and terms of service.
                            </div>
                          </template>
                        </v-checkbox>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-5"></v-divider>
                  <v-row style="flex-direction: row-reverse">
                    <v-col cols="12" lg="4" class="pt-2">
                      <block-button
                        class="site__button w-100"
                        type="submit"
                        :loading="sendRequestLoading"
                        :text="$_t('sendResponse')"
                      >
                      </block-button>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </div>
          </list>
        </v-container>
      </main>
      <footer>
        <div class="wa__social--footer">
          <SocialFooter />
        </div>
      </footer>
    </template>
  </div>
</template>
<script>
import {requiredRules} from "@/mixins/validate"
import privacyPolicy from "@/views/JobOffer/privacyPolicy";
import SocialDivider from "@/components/Global/Section/SocialDivider.vue";
import SocialIcon from "@/components/Global/Header/User/SocialIcon.vue";
import SocialFooter from "@/components/Global/Footer/SocialFooter.vue";
import Header from "@/components/Global/Header/User/Header.vue";
import JobOfferRepository from "@/abstraction/repository/jobOfferRepository";
const jobOfferRepository = new JobOfferRepository();
import { mapActions } from "vuex";
import Dropzone from "@/components/Global/Input/Dropzone";
import { makeid } from "@/utils/math";
const RANDOM_TOKEN = makeid(50);
const head = document.querySelector("head");
export default {
  components: {
    SocialDivider,
    SocialIcon,
    Header,
    Dropzone,
    SocialFooter,
  },
  data() {
    return {
      privacyPolicy,
      form: {
        job_offer_unique_id: null,
        shooting_dates: [],
        file_batch_id: RANDOM_TOKEN,
        question_values: [],
      },
      batch_id: RANDOM_TOKEN,
      offerResponse: {},
      sendRequestLoading: false,
      isSubmitOnce: false,
    };
  },
  computed: {
    requiredRules,
    dropzoneText() {
      return `<div class='d-flex align-center'><i class='WMi-image-svgrepo-com dropzone-icon'></i><div class='text-left'><div class='dropzone-title'>DROP YOUR IMAGES HERE
                ${
                  this.offerResponse.required_files_max_count
                    ? `UP TO ${this.offerResponse.required_files_max_count} FILE `
                    : ""
                }
              </div>
              <div class='dropzone-sub-title'> Allowed Formats:JPG,JPEG | Max FileSize: 20MB </div></div></div>`;
    },
  },
  methods: {
    ...mapActions("jobOffer", ["storeResponse"]),
    async sendResponse() {
      try {
        this.isSubmitOnce = true;
        if (!this.$refs.modelingOfferForm.validate()) {
          return;
        }
        for (const date of this.form.shooting_dates) {
          if (!date.status) {
            return;
          }
        }
        for (const qs of this.form.question_values) {
          if (!qs.value.length){
            return;
          }
        }
        this.sendRequestLoading = true;
        this.form.job_offer_response_status_id =
          this.form.shooting_date === "NG" ? 2 : 1;
        const response = await this.storeResponse(this.form);
        if (response) {
          this.$router.push({
            name: "offerFullSummery",
            params: { locale: this.$_getlocaleParam() },
          });
        }
      } catch (error) {
        return error;
      } finally {
        this.sendRequestLoading = false;
      }
    },
    async loadOfferResponse() {
      try {
        this.form.job_offer_unique_id = this.$route.params.uniqueId;
        this.offerResponse = await jobOfferRepository.showResponseWithUniqueId(
          this.form.job_offer_unique_id
        );
        this.setQuestionValues();
        this.setShootingDates();
      } catch (error) {
        if (error.response.status === 404) {
          this.$router.push({
            name: "dashboard",
            params: {
              locale: this.$_getlocaleParam(),
            },
          });
        }
        return error;
      }
    },
    setQuestionValues(){
      this.offerResponse.questions.map(qs=>{
        this.form.question_values.push({name:qs.name,value:qs.type === 'select'?[]: ""})
      })
    },
    createMetaTag(name, content) {
      var link = document.createElement("meta");
      link.setAttribute("name", name);
      link.setAttribute("content", content);
      head.append(link);
    },
    setShootingDates() {
      this.form.shooting_dates = [...this.offerResponse.dates];
    },
  },
  created() {
    this.createMetaTag("googlebot", "noindex, nofollow");
    this.createMetaTag("robots", "noindex, nofollow");
    this.loadOfferResponse();
  },
};
</script>
<style scoped>
.wa__modeling__offer {
  background-color: #f5f3f3 !important;
}
.social__divider__wrapper {
  width: max-content;
  margin: 30px auto 0 auto;
}
.offer__title {
  font-size: 48px !important;
  text-transform: uppercase;
}
.offer__base {
  padding: 30px 65px;
}
.offer__base p {
  font-size: 18px;
}
.social__hr {
  border-top: 2px solid #928c8c;
}
/* form */
.shooting__date {
  color: #6c6c6c;
  font-family: "Montserrat-Light";
}
.shooting__date--date {
  font-size: 24px;
  font-family: "Montserrat-Light";
}
.shooting__date__note {
  font-size: 14px;
  font-family: "Montserrat-Light";
  height: 36px;
}
.shooting__date__note--title {
  font-size: 14px;
  font-family: "Montserrat-regular";
  font-weight: bold;
}
.nearest__station--label,
.dropzone--label {
  font-size: 14px;
  font-family: "Montserrat-Light";
  color: #acacac;
}
.registered__on {
  font-size: 18px;
}
.registered__on span {
  font-weight: bold;
}
.btn-intended {
  font-family: "montserrat-light";
  border: 1px solid var(--color-gray) !important;
  font-weight: bold;
  font-size: 17px;
}
.active {
  border: 1px solid var(--color-black) !important;
}
.upload {
  border-top: 1px dashed var(--color-gray) !important;
  border-bottom: 1px dashed var(--color-gray) !important;
  position: relative;
}
.checkbox-label {
  font-family: "montserrat-light";
  color: var(--color-black);
  text-transform: lowercase;
  font-size: 20px;
  margin-top: 5px;
}
.font__18 {
  font-size: 18px;
}
.privacy__policy__title {
  font-family: "montserrat";
  font-size: 24px;
}
.privacy__policy__text {
  font-family: "montserrat-light";
  font-size: 20px;
}
.site__login__button {
  font-family: "Barlow-EL";
  font-size: 18px;
  letter-spacing: 2px;
  border: 2px solid var(--color-light-gray);
  border-radius: 4px;
  transition: all 0.6s;
}
.site__login__button:hover {
  background-color: var(--color-black);
  color: var(--color-white);
}
.send-status {
  background-color: #faffff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 20px 10px;
  color: #32cad5;
  font-size: 16px;
  height: 50vh;
}
.send-status .title {
  text-align: center;
  font-size: 26px;
  text-transform: uppercase;
  letter-spacing: 10px;
}
.wa__social--footer {
  background-color: var(--color-black);
}
@media screen and (max-width: 600px) {
  .offer__base {
    padding: 30px 13px;
  }
}
</style>

<style>
.checkbox--check
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input
  .v-icon {
  font-size: 35px !important;
}
.site__input.show-error .v-text-field__details {
  display: block;
}
.vue-dropzone {
  border: none;
  border-radius: 0;
}
</style>
