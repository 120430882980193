const privacyPolicy = 
`Liliana Model
Agency（以下「当社」）は、お客様より預かった個人情報の保護に努めることを社会的責務として認識し、すべての従業<br />
員が以下の項目について取り組むべく宣言いたします。<br /><br />
個人情報の取得について<br />
当社は、適法かつ公正な手段によってのみ、個人情報を取得致します。<br /><br />
個人情報の利用について<br />
当社は、個人情報を取得の際に示した利用目的の範囲内で、業務の遂行上必要な限りにおいて、利用致します。<br />
当社は個人情報を第三者との間で共同利用、または個人情報の取り扱いを第三者
に委託する場合には、当該第三者につき厳正な調査を行ったうえ、個人情報の機密保持について契約を定める等の適切な措置を講じます。<br /><br />
個人情報の第三者提供について<br />
当社は、以下の場合を除いて、お客様の個人情報を第三者に提供することはありません。<br />
お客様が事前に第三者に提供する旨を同意されている場合。<br />
法律等により、提供が必要とされている場合。<br />
個人を識別できない状態で利用する場合。<br />
弊社とお客様の権利、財産、安全などを保護、防御する為に必要であると合理的に判断できる場合。<br /><br />
個人情報の管理について<br />
当社は、個人情報の正確性を保ち、これを安全に管理いたします。<br />
当社は、個人情報の紛失、破壊、改ざん及び漏えいなどを防止するため、不正アクセス、コンピュータウィルス等に対する適正な情報セキュリティ対策を講じます。<br />
当社は、個人情報の持ち出し、外部へ送信する行為等による漏えいは行わせません。<br />
個人情報の開示、訂正、利用停止、消去について<br />
当社は、お客様が自己の個人情報について、開示、訂正、利用停止、消去等を求める権利を有していることを確認し、これらの要求ある場合には、誠意をもって対応します。<br />

なお、当社の個人情報の取り扱いにつきまして、ご意見・ご質問がございましたら、下記の問い合わせ窓口までご連絡下さいますようお願い申し上げます。<br /><br />
Cookie（クッキー）やウェブビーコンについて<br />
当WEBサイトでは、一部のページでCookie（クッキー）やウェブビーコンの仕組みを利用しています。<br />
Cookieとは、WEBブラウザを通してお客さまのコンピューターのハードディスクやメモリーに小さなデータファイルを送ることで、お客さまを確認する仕組みです。ウェブビーコンとは、ウェブページに埋め込まれた小さな画像ファイルやタグで、特定のページにウェブビーコンを埋め込むことにより、そのページが訪問されたか等を確認する仕組みです。<br />
当社では、このCookie、およびウェブビーコンを利用し、WEBサイトの利用状況に関する統計分析、広告などの効果測定を行っています。これはお客さまにより良い情報およびサービスをご提供していくことを目的としています。<br />
Cookieやウェブビーコンによって、お客さまのお名前やご住所、電話番号などが当社に伝わることはありません。<br />
また、お客さまはブラウザの設定により、Cookie機能を無効にすることができます。しかし、Cookie機能を無効にした場合、一部サービスをご利用いただけない場合がありますのでご了承ください。<br /><br />
個人情報に関するお問い合わせ先<br />
Liliana Model Agency<br />
〒150-0041 東京都渋谷区神南1-12-14-719<br />
03-6362-3355<br /><br />
その他の事項<br />
当社が提供するサービスの一部には、お客様から個人情報のご提供を受けない場合に提供できないものがありますことを予めご承知おきください。<br />
当社では、より良い個人情報保護を実現するために、また、個人情報保護法その他関係法令の制改定に伴い、個人情報保護方針を改定することがございます。定期的にご確認頂きますようお願い致します。<br />
16歳未満のお客様は、保護者の方の同意を得た上で個人情報をご提供いただきますようお願い致します。<br />
本ページの内容は、掲載日以降に適用致します。`

export default privacyPolicy;